import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useSendResetPasswordMailMutation } from "apis/services/auth";
import Text from "components/general/Text";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import showSuccessMsg from "utils/showSuccessMsg";
import styles from "./styles.module.scss";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [sendResetPasswordMail] = useSendResetPasswordMailMutation();

  const handleSubmit = (values: { email: string }) => {
    sendResetPasswordMail(values)
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Code has been sent." });
        navigate(`/auth/verify-code/?email=${values.email}`);
      });
  };

  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}> </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true },
            { type: "string" },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <TextInput i18nLabelKey="EMAIL" />
        </Form.Item>

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
