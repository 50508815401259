import { Outlet, useRoutes } from "react-router-dom";
import SidebarLayout from "components/wrappers/SidebarLayout";

// Main Screens Imports
import Home from "pages/Home";
import Login from "pages/Auth/Login";
import AuthLayout from "components/wrappers/AuthLayout";
import ForgotPassword from "pages/Auth/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword";
import Activity from "pages/Activity";
import ProtectedOutlet from "components/wrappers/ProtectedOutlet";
import Notifications from "pages/notifications";
import Shifts from "pages/Shifts";
import Reports from "pages/Reports";
import Settings from "pages/settings";
import Users from "pages/settings/Users";
import VerifyCode from "pages/Auth/VerifyCode";
import StaffDirectory from "pages/StaffDirectory";
import MyProfile from "pages/MyProfile";
import organizationRoutes from "./organizationRoutes";
import userAccessControlRoutes from "./userAccessControlRoutes";
import calenderRoutes from "./calenderRoutes";
import staffRoutes from "./staffRoutes";
import requestsRoutes from "./requestsRoutes";
import setupsRoutes from "./setupsRoutes";
import integrationRotues from "./integrationRoutes";
// End Main Screens Imports

export default function useGenerateRoutes() {
  const routes = [
    {
      path: "/",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        // Main Screens
        {
          path: "",
          element: <Home />,
        },
        {
          path: "staff",
          children: [
            {
              path: "",
              element: <Users />,
            },
          ],
        },
        {
          path: "shifts",
          element: <Shifts />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        // {
        //   path: "settings/setups",
        //   element: <Setups />,
        // },
        {
          path: "staff-directory",
          element: <StaffDirectory />,
        },
        {
          path: "my-profile",
          element: <MyProfile />,
        },
        {
          path: "activity",
          element: <Activity />,
        },
        {
          path: "notifications",
          element: <Notifications />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        userAccessControlRoutes,
        organizationRoutes,
        calenderRoutes,
        staffRoutes,
        requestsRoutes,
        setupsRoutes,
        integrationRotues,
        // End Main Screens
      ],
    },
    {
      path: "/auth/",
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [
        {
          path: "",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "verify-code",
          element: <VerifyCode />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
      ],
    },
  ];

  return useRoutes(routes);
}
