/* eslint-disable */

import Text from "components/general/Text";
import moment from "moment";
import styles from "./styles.module.scss";
import { StateType } from "./types";
import Button from "../Button";

export default function CalendarRow({
  dataItem,
  onClickHandler,
  showActionButton = true,
  isPublicHoliday,
}: any) {
  const date = moment(dataItem.date).format("ddd DD/ MMM");
  const isFutureDate = moment(dataItem.date).isAfter(moment());

  const StateTypeMap = new Map<string, string>([
    [StateType.success, StateType.success],
    [StateType.absent, StateType.absent],
    [StateType.permission, StateType.permission],
    [StateType.conflict, StateType.conflict],
    [StateType.off, StateType.off],
    [StateType.publicHoliday, StateType.publicHoliday],
  ]);

  const stateClassName =
    StateTypeMap.get(
      isPublicHoliday?.isHoliday ? "publicHoliday" : dataItem?.state
    ) || "";
  const borderStateClassName =
    `${`${StateTypeMap.get(
      isPublicHoliday?.isHoliday ? "publicHoliday" : dataItem?.state
    )}Border`}` || "";

  const IsFutureContent = () => (
    <>
      {StateTypeMap.get(dataItem?.state) === StateType.absent ||
      StateTypeMap.get(dataItem?.state) === StateType.success ? (
        <div className={styles.content}>
          <Text
            style={{ fontSize: "16px", textTransform: "capitalize" }}
            className="me-1"
          >
            {date} :{" "}
            {isPublicHoliday?.isHoliday
              ? `${isPublicHoliday?.name},`
              : `${dataItem?.specification},`}
          </Text>

          <Text style={{ fontSize: "16px" }}>
            {dataItem.shiftType && (
              <span className={styles.successTxt}>
                {`${dataItem.shiftType} Shift`}{" "}
              </span>
            )}
          </Text>

          {dataItem.start_time && (
            <Text style={{ fontSize: "16px" }}>, {dataItem.start_time}</Text>
          )}
          {dataItem.working_hour && (
            <Text style={{ fontSize: "16px" }}>
              {`, ${dataItem.working_hour || 0} hours`}
            </Text>
          )}
        </div>
      ) : (
        <div className={styles.content}>
          <Text
            style={{ fontSize: "16px", textTransform: "capitalize" }}
            className="me-1"
          >
            {date} :{" "}
            {isPublicHoliday?.isHoliday
              ? `${isPublicHoliday?.name}`
              : StateTypeMap.get(dataItem?.state)}
          </Text>
        </div>
      )}
    </>
  );

  return (
    <div className={`${styles.container} ${styles[stateClassName]}`}>
      <div className="d-flex align-items-center">
        <div
          className={`${styles.startBorder} ${styles[borderStateClassName]}`}
        />
        {isFutureDate ? (
          <IsFutureContent />
        ) : (
          <>
            {StateTypeMap.get(dataItem?.state) === StateType.absent ||
            StateTypeMap.get(dataItem?.state) === StateType.success ? (
              <div className={styles.content}>
                <Text
                  style={{ fontSize: "16px", textTransform: "capitalize" }}
                  className="me-1"
                >
                  {date} :{" "}
                  {dataItem.state.charAt(0).toUpperCase() +
                    dataItem.state.slice(1)}
                  ,
                </Text>

                <Text style={{ fontSize: "16px" }}>
                  {dataItem.shiftType && (
                    <span className={styles.successTxt}>
                      {`${dataItem.shiftType} Shift`}{" "}
                    </span>
                  )}
                </Text>

                <Text style={{ fontSize: "16px" }}>{dataItem.start_time}</Text>

                <Text style={{ fontSize: "16px" }}>
                  {`, ${dataItem.working_hour} hours`}
                </Text>
              </div>
            ) : (
              <div className={styles.content}>
                <Text
                  style={{ fontSize: "16px", textTransform: "capitalize" }}
                  className="me-1"
                >
                  {date} :{" "}
                  {isPublicHoliday?.isHoliday
                    ? `${isPublicHoliday?.name}`
                    : StateTypeMap.get(dataItem?.state)}
                </Text>
              </div>
            )}
          </>
        )}

        {/* {StateTypeMap.get(dataItem?.state) === StateType.success ? (
          <div className={styles.content}>
            <Text style={{ fontSize: "16px" }} className="me-1">
              {date} :
            </Text>

            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && (
                <span className={styles.successTxt}>
                  {`${dataItem.shiftType} Shift`}{" "}
                </span>
              )}
              Start:
              {hours}, {`${dataItem.working_hour} hours` ?? "N/A"}
            </Text>
            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && <span>{`, checkin time: `} </span>}
              {String(moment(dataItem?.checkin?.checkin_time).format("LT"))}
            </Text>
            <Text style={{ fontSize: "16px" }}>
              {dataItem.shiftType && <span>{`, checkout time: `} </span>}
              {String(moment(dataItem?.checkin?.checkout_time).format("LT"))}
            </Text>
          </div>
        ) : (
          <div className={styles.content}>
            <Text
              style={{ fontSize: "16px", textTransform: "capitalize" }}
              className="me-1"
            >
              {date} :{" "}
              {isPublicHoliday?.isHoliday
                ? `${isPublicHoliday?.name}`
                : StateTypeMap.get(dataItem?.state)}
            </Text>
          </div>
        )} */}
      </div>

      {showActionButton && (
        <Button
          btnClassName={styles.button}
          onClick={() => onClickHandler?.(dataItem?.id)}
        >
          ...
        </Button>
      )}
    </div>
  );
}
