import api from "apis";
import { AuthTokenResponse, LoginBody, User } from "../types/auth";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStaffInfo: build.query<User, void>({
      query: () => ({
        url: "/auth/staff/me/",
      }),
      providesTags: ["User"],
    }),

    login: build.mutation<AuthTokenResponse, LoginBody>({
      query: (body) => ({
        url: "/auth/users/login/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["User"],
    }),

    logout: build.mutation({
      query: () => ({
        url: "/auth/users/logout/",
        method: "POST",
      }),
    }),

    sendResetPasswordMail: build.mutation({
      query: (params) => ({
        url: "/auth/forget_password/send_reset_email/",
        method: "POST",
        body: params,
      }),
    }),
    checkResetCode: build.mutation({
      query: (params) => ({
        url: "/auth/forget_password/check_reset_code/",
        method: "POST",
        body: params,
      }),
    }),
    resetPassword: build.mutation({
      query: (params) => ({
        url: "/auth/forget_password/reset_password/",
        method: "POST",
        body: params,
      }),
    }),
  }),
});

export const {
  useGetStaffInfoQuery,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useSendResetPasswordMailMutation,
  useCheckResetCodeMutation,
} = authApi;
