import { Form } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "apis/services/auth";
import Text from "components/general/Text";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import showSuccessMsg from "utils/showSuccessMsg";
import styles from "./styles.module.scss";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [resetPassword] = useResetPasswordMutation();

  const handleSubmit = (values: { code: string }) => {
    resetPassword({
      ...values,
      email: searchParams.get("email"),
      code: searchParams.get("code"),
    })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Your password has been reset." });
        navigate(`/auth`);
      });
  };

  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}>Enter your new password</Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleSubmit}
      >
        <Form.Item name="password" rules={[{ required: true }]}>
          <TextInput i18nLabelKey="NEWPASSWORD" type="password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <TextInput i18nLabelKey="CONFIRMPASSWORD" type="password" />
        </Form.Item>

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
