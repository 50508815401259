import { Form } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCheckResetCodeMutation,
  useSendResetPasswordMailMutation,
} from "apis/services/auth";
import Text from "components/general/Text";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import showSuccessMsg from "utils/showSuccessMsg";
import styles from "./styles.module.scss";

export default function VerifyCode() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [checkResetCode] = useCheckResetCodeMutation();
  const [sendResetPasswordMail] = useSendResetPasswordMailMutation();

  const handleSubmit = (values: { code: string }) => {
    checkResetCode(values)
      .unwrap()
      .then(() => {
        navigate(
          `/auth/reset-password/?email=${searchParams.get("email")}&code=${
            values.code
          }`
        );
      });
  };

  const resendVerifyCode = () => {
    sendResetPasswordMail({
      email: searchParams.get("email"),
    })
      .unwrap()
      .then(() => {
        showSuccessMsg({ msg: "Code has been sent." });
      });
  };

  return (
    <>
      <Text className={styles.title}>Forgot password</Text>
      <Text className={styles.secondaryTitle}>
        We&rsquo;ve sent a verification code to your email
      </Text>
      <Form
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleSubmit}
      >
        <Form.Item name="code" rules={[{ required: true }, { type: "string" }]}>
          <TextInput i18nLabelKey="CODE" />
        </Form.Item>
        <div className={styles.resendCode}>
          <Text className={styles.text}>Didn’t Receive Code?</Text>
          &nbsp;
          <button
            type="button"
            className={styles.link}
            onClick={() => resendVerifyCode()}
          >
            Resend Code
          </button>
        </div>

        <Button btnClassName={styles.submitBtn} type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
